import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const TandC = () => {
  return (
    <>
      <Navbar />

      <div className="training-program">
        <h1>TERMS AND CONDITIONS</h1>
        <p>
          By accessing and using SoulSportza's services, you agree to comply
          with these terms and conditions. Membership is subject to adherence to
          academy rules and regulations. Fees are non-refundable except under
          specific conditions outlined in our refund policy. SoulSportza
          reserves the right to modify programs and schedules. Members are
          responsible for their personal belongings and must follow safety
          guidelines. Violations may result in termination of membership without
          a refund.
        </p>
      </div>

      <Footer />
    </>
  );
};

export default TandC;
