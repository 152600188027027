import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';
// import './Location.css';

const Location = () => {
  return (
    <>
      <Navbar />
      <div className="location-container">
        <h1>Our Location</h1>
        <div className="map-container">
          <iframe
            title="Soul Sportza Sports Academy Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7616.098125802295!2d78.564201!3d17.361372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xab94f9a549e36725%3A0xd50f7062a7e6284f!2sSoul%20sportza%20Sports%20Academy!5e0!3m2!1sen!2sin!4v1726166772988!5m2!1sen!2sin"
            // width="600"
            // height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="location-info">
          <p><strong>Soul Sportza Sports Academy</strong></p>
          <p>Rock Town Residents Colony, Mannava Enclave, Chanakyapuri, Hyderabad, Telangana 500068</p>
          <p><a href="https://www.google.com/maps?ll=17.361372,78.564201&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=15352613223755294799" target="_blank" rel="noopener noreferrer">Get Directions</a></p>
          <p>5.0 - 2 reviews</p>
          <a href="https://www.google.com/maps?ll=17.361372,78.564201&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=15352613223755294799" target="_blank" rel="noopener noreferrer">View larger map</a>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Location;
