import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const WellMaintained = () => {
  return (
    <>
      <Navbar />
      
      <div className="ot-container">

      <div className='ot-container-img'>
      <img src="./images/image2.jpg" alt="Landscape 2" />
      <h5>SKATING TRACK</h5>
      </div>
        <h3>Well-Maintained Grounds</h3>
        <p>SoulSportza boasts meticulously maintained grounds and facilities:</p>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Modern Equipment:</strong>Top-quality sports equipment and training aids.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Cleanliness:</strong> Regular maintenance ensuring safe and hygienic conditions.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Comfortable Amenities:</strong>  Locker rooms, rest areas, and refreshment zones.</p>
        </div>
      </div>

      <div className="collage-container" style={{margin:'auto',marginBottom:'50px'}}>
        <img src="./images/image1.jpg" alt="Landscape 1" className="collage-image" />
        <h5>FOOTBALL GROUND</h5>
        <img src="./images/image2.jpg" alt="Landscape 2" className="collage-image" />
        <h5>SKATING TRACK</h5>
        <img src="./images/image3.jpg" alt="Landscape 3" className="collage-image" />
        <h5>CRICKET NETS</h5>
        <img src="./images/image4.jpg" alt="Landscape 4" className="collage-image" />
        <h5>BASKETBALL COURT</h5>
        <img src="./images/image5.jpg" alt="Landscape 5" className="collage-image" />
        <h5>CRICKET NETS</h5>
        <img src="./images/image6.jpg" alt="Landscape 2" className="collage-image" />
        <h5>FOOTBALL GROUND</h5>
        <img src="./images/image7.jpg" alt="Landscape 3" className="collage-image" />
        <h5>SKATING TRACK</h5>
        <img src="./images/image8.jpg" alt="Landscape 4" className="collage-image" />
        <h5>TENNIS COURT</h5>
        <img src="./images/image9.jpg" alt="Landscape 5" className="collage-image" />
        <h5>TENNIS COURT</h5>
      </div>

      <Footer />
    </>
  );
}

export default WellMaintained;
