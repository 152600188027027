import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const AboutSkating = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Skating Training Program at Soul Sportza</h1>
        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/canva-skating.jpg" alt="Skating" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Physical Conditioning</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Cardiovascular Training: Endurance exercises such as
                  long-distance skating, interval training, and sprinting
                  drills.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Strength Training: Exercises targeting lower body muscles,
                  core stability, and overall strength.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Agility and Balance: Drills to improve footwork, agility, and
                  balance, essential for effective skating.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Flexibility: Stretching routines and mobility exercises to
                  enhance flexibility and prevent injuries.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Technical Skills Development</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Skating Techniques: Drills focusing on proper skating form, speed,
              and efficiency.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Turning and Stopping: Techniques for sharp turns, quick stops, and
              effective maneuvering.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Jumping and Tricks: Training for jumps, spins, and other skating
              tricks to enhance performance.
            </p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/skt3.jpg" alt="Skating" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Tactical Training</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Competition Preparation: Strategies for competitive skating,
                  including race tactics and mental preparation.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Team Skating: Training for synchronized skating and team-based
                  drills.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Mental Preparation</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Focus and Concentration: Mental exercises to improve focus and
              decision-making under pressure.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Confidence Building: Techniques to boost confidence and
              self-belief in competitive scenarios.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Match Play</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Practice Sessions: Simulated match conditions to apply learned
              skills in a competitive setting.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Competitive Drills: Drills to replicate match scenarios and
              develop mental toughness.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Cool Down: Structured cool-down routines to aid muscle recovery
              and reduce injury risk.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Rest and Nutrition: Emphasis on adequate rest, hydration, and
              nutrition for overall well-being.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutSkating;
