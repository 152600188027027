

import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';

const AboutBasketball = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Basketball Training Program at Soul Sportza</h1>
        <div className="home-1-container">
        <div className="home-1-box1">
          <img src="./images/wallball02.jpg" alt="Sports" />
        </div>
        <div className="home-1-box2">
          
          <div className="section">
          <h2>Physical Conditioning</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Aerobic Conditioning: Running drills, interval training, and sprinting exercises to improve cardiovascular endurance and stamina.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Strength Training: Exercises targeting upper body, lower body, and core muscles to enhance strength, power, and explosiveness.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Agility and Speed Training: Cone drills, ladder drills, and agility ladder exercises to improve footwork, quickness, and agility on the court.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Flexibility and Mobility: Stretching exercises, dynamic warm-ups, and foam rolling to improve flexibility, range of motion, and injury prevention.</p>
          </div>
        </div>
        </div>
      </div>
      
      
        <div className="section">
          <h2>Technical Skills Development</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Shooting: Shooting drills focusing on shooting form, accuracy, range, and shot selection from various spots on the court.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Ball Handling: Dribbling drills including stationary dribbling, ball control exercises, and dribble moves to improve ball-handling skills and control.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Passing: Passing drills focusing on accuracy, timing, and decision-making, including chest passes, bounce passes, and overhead passes.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Defense: Defensive drills including stance work, closeouts, lateral movement, and defensive positioning to improve on-ball defense and team defense.</p>
          </div>
        </div>

        <div className="home-1-container">
        <div className="home-1-box1">
          <img src="./images/wallball01.jpg" alt="Sports" />
        </div>
        <div className="home-1-box2">
        <div className="section">
          <h2>Tactical Training</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Offensive Concepts: Learning offensive sets, spacing, player movement, and ball movement to create scoring opportunities and exploit defensive weaknesses.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Defensive Strategies: Understanding defensive principles, rotations, help defense, and communication to disrupt the opponent's offense and force turnovers.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Game Situations: Practicing situational basketball, such as fast breaks, pick-and-roll scenarios, transition defense, and end-of-game situations.</p>
          </div>
        </div>
        </div>
      </div>


        
        <div className="section">
          <h2>Mental Preparation</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Focus and Concentration: Incorporating mental exercises, visualization techniques, and focus drills to enhance concentration and decision-making under pressure.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Confidence Building: Providing positive reinforcement, setting achievable goals, and fostering a supportive team environment to boost players' confidence and self-belief.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Resilience and Adaptability: Encouraging players to learn from setbacks, stay composed under pressure, and adapt to changing game situations.</p>
          </div>
        </div>
        <div className="section">
          <h2>Match Play</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Practice Matches: Organizing intra-squad matches, net sessions, and practice games to simulate match conditions and allow players to apply learned skills and tactics in a competitive setting.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Competitive Drills: Competitive drills such as nets challenges, scenario-based games, and pressure situations to replicate match scenarios and develop mental toughness.</p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cool Down: Including a structured cool-down routine with stretching and mobility exercises to aid in muscle recovery and reduce the risk of injury.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Rest and Nutrition: Emphasizing the importance of adequate rest, hydration, and nutrition to support players' physical recovery and overall well-being.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutBasketball;
