import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const PrivacyPolicy = () => {
  return (
    <>
      <Navbar />

      <div className="training-program">
      <h1>PrivacyPolicy</h1>

{/* <h2>Privacy Policy</h2> */}
<p>
SoulSportza respects your privacy and is committed to protecting your personal data. This privacy policy outlines how we collect, use, and safeguard your information when you use our services. We collect personal information such as name, contact details, and payment information to provide and improve our services. We do not share your information with third parties except for necessary service providers and legal requirements. We use cookies to enhance user experience. By using our website, you agree to our privacy practices.
</p>
      </div>

      <Footer />
    </>
  );
};

export default PrivacyPolicy;
