import React from "react";
import "./Footer.css";
import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
  FaPhoneSquare,
  FaPhoneSquareAlt,
  FaPinterestSquare,
  FaTelegram,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-box1">
        <Link style={{ textDecoration: "none" }} to="/tennisclasses">
          <button>
            1. Tennis Classes
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />
          </button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/footballclasses">
          <button>
            2. Football Classes
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />
          </button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/basketballclasses">
          <button>
            3. Basketball Classes
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />
          </button>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/otherclasses">
          <button>
            Other Classes
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />
          </button>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/wellmaintained">
          <button>
            Well-Maintained Grounds
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />{" "}
          </button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/healthhub">
          <button>
            Health Hub at SoulSportza
            <img
              src="./images/flagicon.png"
              alt="Flag Icon"
              className="flag-icon"
            />
          </button>
        </Link>
      </div>

      <div className="footer-box2">
        <div className="contact-info" style={{ gap: "20px", marginTop: "5px" }}>
          <Link style={{ textDecoration: "none" }} to="/tandc">
            <h5 style={{ color: "#63cf48" }}>
              Terms and Conditions
              <img
                src="./images/flagicongreen.png"
                alt="Flag Icon"
                className="flag-icon"
              />
            </h5>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/privacypolicy">
            <h5 style={{ color: "#63cf48" }}>
              Privacy Policy
              <img
                src="./images/flagicongreen.png"
                alt="Flag Icon"
                className="flag-icon"
              />{" "}
            </h5>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/refundpolicy">
            <h5 style={{ color: "#63cf48" }}>
              Refund Policy
              <img
                src="./images/flagicongreen.png"
                alt="Flag Icon"
                className="flag-icon"
              />
            </h5>
          </Link>

          <Link style={{ textDecoration: "none" }} to="/faqs">
            <h5 style={{ color: "#63cf48" }}>
              FAQ'S
              <img
                src="./images/flagicongreen.png"
                alt="Flag Icon"
                className="flag-icon"
              />
            </h5>
          </Link>
        </div>
      </div>

      <div className="footer-box2">
        <div className="contact-info" style={{ marginTop: "5px" }}>
          <span>
            <FaPhoneSquareAlt className="footer-icon" /> +91 73311 10064
          </span>
          <span>
            Contact Us:{" "}
            <a
              href="mailto:info@soulsportza.com"
              style={{ textDecoration: "none", color: "#fff" }}>
              info@soulsportza.com
            </a>
          </span>

          <p>Be the first to know!</p>
          <div className="social-icons">
            <Link to="https://www.facebook.com/sharer/sharer.php?u=https://soulsportza.com/contact-us/">
              <FaFacebookSquare className="footer-icon" />
            </Link>
            {/* <Link to="/">
              <FaTwitterSquare className="footer-icon" />
            </Link>
            <Link to="/">
              <FaInstagramSquare className="footer-icon" />
            </Link>
            <Link to="/">
              <FaYoutubeSquare className="footer-icon" />
            </Link> */}
            
            <Link to="https://twitter.com/share?url=https://soulsportza.com/contact-us/">
              <FaTwitterSquare className="footer-icon" />
            </Link>
            <Link to="https://pinterest.com/pin/create/button/?url=https://soulsportza.com/contact-us/&media=https://soulsportza.com/wp-includes/images/media/default.svg">
              <FaPinterestSquare className="footer-icon" />
            </Link>
            <Link to="https://www.linkedin.com/shareArticle?mini=true&url=https://soulsportza.com/contact-us/">
              <FaLinkedin className="footer-icon" />
            </Link>
            <Link to="https://telegram.me/share/url?url=https://soulsportza.com/contact-us/">
              <FaTelegram className="footer-icon" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
