import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';


const AboutKarate = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Karate Training Program at Soul Sportza</h1>
        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/KARATE03.jpg" alt="Karate" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Physical Conditioning</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cardiovascular Training: Endurance exercises like running, skipping, and high-intensity interval training (HIIT).</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Strength Training: Exercises to develop muscle strength, power, and explosiveness, such as push-ups, squats, and weight training.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Flexibility: Stretching routines and flexibility exercises to improve range of motion and prevent injuries.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Agility: Drills to enhance speed, quickness, and coordination, crucial for effective movement in karate.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="section">
          <h2>Technical Skills Development</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Basic Techniques: Drills focusing on fundamental karate techniques such as punches, kicks, and blocks.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Forms (Kata): Practice of katas, which are sequences of movements that represent offensive and defensive techniques.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Sparring (Kumite): Controlled sparring sessions to apply techniques in a combat scenario and develop timing and distance control.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Self-Defense: Techniques for practical self-defense situations, focusing on real-world applications of karate skills.</p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/KARATE02.JPG" alt="Karate" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Tactical Training</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Offensive and Defensive Strategies: Training on how to effectively attack and defend, including combinations and counterattacks.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Footwork: Drills to improve movement, positioning, and balance during a match.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Situational Training: Practicing specific scenarios to develop quick thinking and adaptability in various combat situations.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Mental Preparation</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Focus and Concentration: Mental exercises to enhance focus, concentration, and awareness during training and competition.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Confidence Building: Techniques to build self-confidence and mental resilience in challenging situations.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Stress Management: Strategies to manage stress and maintain composure under pressure.</p>
          </div>
        </div>
        <div className="section">
          <h2>Match Play</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Practice Matches: Simulated matches to apply learned techniques and strategies in a competitive setting.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Competitive Drills: Drills designed to replicate match scenarios and develop mental toughness.</p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cool Down: Structured cool-down routines to aid muscle recovery and reduce the risk of injury.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Rest and Nutrition: Emphasis on adequate rest, hydration, and nutrition to support physical recovery and overall well-being.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutKarate;
