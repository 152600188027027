import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import { Stack } from "@mui/material";
import "./navbar.css";
import InputBase from '@mui/material/InputBase';
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from "@material-ui/icons/Menu";


const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(18),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "#000",
    fontSize: "15px",
    margin:'auto',
    marginLeft: theme.spacing(5),
    borderBottom: "1px solid transparent",
    "&:hover": {
      color: "#fff",
      fontSize: "15.5px",
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.white,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(5),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 18),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#000',
    
    
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    
    width: '100%',
    paddingLeft:'20px',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

function Navbar() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="sticky"
      style={{ padding: "10px 10px" }}
      className="navbar">
      <CssBaseline />
      <Toolbar style={{ justifyContent: "space-between" }}>
        <Link to="/" className={classes.link}>
          <Stack>
            <img
              className="Nav-logo"
              src="./images/soulvert-logo.png"
              alt="main-logo"
            />
          </Stack>
        </Link>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div className={classes.navlinks}>
            <Link
              to="/"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              <h4>HOME</h4>
            </Link>
            <Link
              to="/otherclasses"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              <h4>CLASSES</h4>
            </Link>
            <Link
              to="/location"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              <h4>LOCATION</h4>
            </Link>
            <Link
              to="/blog"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              <h4>BLOG</h4>
            </Link>

            <Link
              to="/contactpage"
              className={classes.link}
              id="heading1"
              style={{ marginLeft: "30px" }}>
              <h4>CONTACT</h4>
            </Link>

            {/* <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
              />
            </div> */}
          

          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}
export default Navbar;
