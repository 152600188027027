import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const RefundPolicy = () => {
  return (
    <>
      <Navbar />
      
      <div className="training-program">
      <h1>Refund Policy</h1>
        <p>Refunds are available under the following conditions:</p>
        <ul>
          <li>
            Full refund if cancellation is made within 7 days of enrollment.
          </li>
          <li>
            Partial refund (50%) if cancellation is made within 14 days of
            enrollment.
          </li>
          <li>No refund if cancellation is made after 14 days.</li>
          <li>
            In case of medical emergencies, refunds will be considered on a
            case-by-case basis with valid documentation.
          </li>
        </ul>
      </div>

      <Footer />
    </>
  );
};

export default RefundPolicy;
