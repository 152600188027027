import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Faqs = () => {
  return (
    <>
      <Navbar />

      <div className="training-program">
        <h1>Faqs</h1>
        <div>
          <strong>Q: What sports do you offer?</strong>
          <p>
            A: We offer cricket, basketball, football, tennis, skating, karate,
            dance, Zumba, and fitness programs.
          </p>
        </div>
        <div>
          <strong>Q: What are your timings?</strong>
          <p>
            A: Our sessions run from 6:00 AM to 8:00 AM and 4:00 PM to 6:00 PM.
          </p>
        </div>
        <div>
          <strong>Q: How do I register?</strong>
          <p>
            A: You can register online through our website or visit our academy
            in person.
          </p>
        </div>
        <div>
          <strong>Q: Do you provide health support?</strong>
          <p>
            A: Yes, we have a partnership with Kamineni Hospital for regular
            health check-ups and medical support.
          </p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Faqs;
