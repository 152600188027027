

import React from 'react';
import Footer from '../Footer';
import Navbar from '../Navbar';

const AboutTennis = () => {
  return (
    <>
    
    <Navbar />

    
    <div className="training-program">
      <h1>Tennis Training Program at Soul Sportza</h1>
      <div className="home-1-container">
        <div className="home-1-box1">
          <img src="./images/canva-tennis.jpg" alt="Sports" />
        </div>
        <div className="home-1-box2">
        <div className="section">
        <h2>Physical Conditioning</h2>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Aerobic Conditioning: Running drills, interval training, and footwork exercises to improve cardiovascdivar endurance and on-court movement.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Strength Training: Exercises targeting upper body, lower body, and core muscles to enhance strength, power, and stability.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Agility and Speed Training: Cone drills, ladder drills, and reaction drills to improve agility, quickness, and explosiveness on the court.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Flexibility and Mobility: Stretching exercises, yoga, and dynamic warm-ups to improve flexibility, range of motion, and injury prevention.</p>
        </div>
      </div>
        </div>
      </div>
      
      
      <div className="section">
        <h2>Technical Skills Development</h2>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Groundstrokes: Forehand and backhand drills focusing on technique, consistency, depth, and placement.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Volleys: Net drills and volley exercises to improve net play, reflexes, and touch at the net.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Serves and Returns: Serving practice, return drills, and serve-and-volley exercises to improve accuracy, power, and variety in serving and returning.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Footwork: Movement drills, split-step practice, and court positioning exercises to improve footwork, balance, and anticipation.</p>
        </div>
      </div>
      <div className="section">
        <h2>Tactical Training</h2>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Shot Selection: Analyzing opponents' weaknesses, developing game plans, and practicing shot selection based on match situations.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Point Construction: Working on patterns of play, strategies for dictating play, and transitioning between offense and defense.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Mental Toughness: Incorporating mental exercises, visualization techniques, and focus drills to enhance concentration, confidence, and resilience during matches.</p>
        </div>
      </div>
      <div className="section">
        <h2>Match Play</h2>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Practice Matches: Organizing practice matches against different opponents to apply learned skills, tactics, and strategies in a competitive setting.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Competitive Drills: Competitive drills such as tiebreakers, pressure point scenarios, and situational games to simulate match conditions and develop mental toughness.</p>
        </div>
      </div>
      <div className="section">
        <h2>Regeneration and Recovery</h2>
        <div>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cool Down: Including a structured cool-down routine with stretching and foam rolling to aid in muscle recovery and reduce the risk of injury.</p>
          <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Rest and Nutrition: Emphasizing the importance of adequate rest, hydration, and nutrition to support players' physical recovery and overall well-being.</p>
        </div>
      </div>
    </div>
    
    <Footer />
    
    </>
  );
};

export default AboutTennis;
