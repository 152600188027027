
import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const OtherClasses = () => {
  return (
    <>
    <Navbar />
    
    <h1 style={{marginTop:'50px'}}>OtherClasses</h1>

    <div className="footer-box1" style={{marginTop:'50px',marginBottom:'100px',background:'#000000df'}}>
        <Link style={{ textDecoration: "none" }} to="/zumbaclasses">
          <button>4. Zumba Classes<img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /></button>
        </Link>
        <Link style={{ textDecoration: "none" }} to="/karateclasses">
          <button>5. Karate Classes<img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /></button>
        </Link>
      
        <Link style={{ textDecoration: "none" }} to="/skatingclasses">
          <button>6. Skating Classes<img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /></button>
        </Link>

        <Link style={{ textDecoration: "none" }} to="/cricketclasses">
          <button>7. Cricket Classes<img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /></button>
        </Link>


        <Link style={{ textDecoration: "none" }} to="/fitnessathleticclasses">
          <button>8. Fitness Athletic Classes<img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /></button>
        </Link>

      </div>

    <Footer />
    
    </>
  )
}

export default OtherClasses;