import React from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

const Blog = () => {
  return (
    <>
      <Navbar />

      <h1>Blog</h1>

      <Footer />
    </>
  );
};

export default Blog;
