

import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const FitnessAthleticClasses = () => {
  return (
    <>Fitness & Athletic
    <Navbar />
    
    {/* <div>FitnessAthleticClasses</div> */}

    <div className="classes-container">
      <div className="box box1">
        <h1>Fitness & Athletic Classes</h1>
        <img style={{width:'70%'}} src="./images/canva-fitness.jpg" alt="Classes" />
        <h3>
        Fitness & Athletic
        </h3>
        
        <div>
      <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Coaching Techniques:</strong> Core skills such as dribbling, passing, shooting, and defending. Advanced techniques include positional play, set-pieces, and game tactics.</p>
      <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Ground Practicing Sessions:</strong> Drills for ball control, small-sided games, tactical exercises, and endurance training.</p>
    </div>
      </div>
      <div className="box box2">
      <div className="classes-box1">
        
        <Link style={{ textDecoration: "none" }} to="/aboutfitnessathletic">
          <button><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />{" "}About{" "}</button>
        </Link>
        
        <Link style={{ textDecoration: "none" }} to="/contactpage">
          <button><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Contact</button>
        </Link>
        
      </div>


        <h2>Contact Us</h2>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>

    <Footer />
    
    </>
  )
}

export default FitnessAthleticClasses;