import React from "react";
import Navbar from "../Navbar";
import "../../style.css";
import Footer from "../Footer";

const Home = () => {
  return (
    <>
      <Navbar />
      <div className="main-heading">
        <h1>Soulsportza</h1>
      </div>

      <div className="home-1-container">
        <div className="home-1-box1">
          <img src="./images/home-1-img1.jpg" alt="Sports" />
        </div>
        <div className="home-1-box2">
          <h2>About us</h2>
          <p>
            SoulSportza is Hyderabad's premier sports academy, dedicated to
            nurturing athletic talent through world-class training facilities
            and expert coaching. We offer diverse programs tailored to all age
            groups, promoting physical fitness, teamwork, and sportsmanship. Our
            mission is to inspire a lifelong passion for sports and healthy
            living.
          </p>
        </div>
      </div>

      <div className="home-1-container">
        
        
      <div className="home-1-box1" style={{padding:'0',margin:'0'}}>
          <img
            style={{ width: "80%" }}
            src="./images/image9.jpg"
            alt="Sports"
          />
          <h5>TENNIS COURT</h5>
      </div>
        
        <div className="home-1-box2">
          <h1>Benefits of Training with Us</h1>
          <div class="features-list">
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Professional Coaching:</strong> Experienced and certified
              coaches providing personalized training.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Comprehensive Programs:</strong> Wide range of sports and
              fitness activities catering to all age groups.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Health and Safety:</strong> Partnership with Kamineni
              Hospital for comprehensive health support.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>State-of-the-Art Facilities:</strong> Modern,
              well-maintained training grounds and equipment.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Holistic Development:</strong> Focus on physical fitness,
              mental toughness, and sportsmanship.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Convenient Timings:</strong> Flexible morning and evening
              training sessions.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              <strong>Success Stories:</strong> Proven track record of athlete
              achievements and positive testimonials.
            </p>
          </div>
        </div>

        
      </div>

      <div className="home-1-container" style={{margin:'0'}}>
        <div className="home-1-box2">
        <img src="./images/image4.jpg" alt="Landscape 1" className="collage-image" />
        {/* <img src="./images/image5.jpg" alt="Landscape 2" className="collage-image" /> */}
        <h5>BASKETBALL COURT</h5>
        </div>

        <div className="home-1-box2">
        {/* <img src="./images/image4.jpg" alt="Landscape 1" className="collage-image" /> */}
        <img src="./images/image5.jpg" alt="Landscape 2" className="collage-image" />
        <h5>CRICKET NETS</h5>
        </div>

        <div className="home-1-box2">
        <img src="./images/image6.jpg" alt="Landscape 1" className="collage-image" />
        {/* <img src="./images/image5.jpg" alt="Landscape 2" className="collage-image" /> */}
        <h5>FOOTBALL GROUND</h5>
        </div>
        <div className="home-1-box2">
        {/* <img src="./images/image6.jpg" alt="Landscape 1" className="collage-image" /> */}
        <img src="./images/image2.jpg" alt="Landscape 2" className="collage-image" />
        <h5>SKATING TRACK</h5>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Home;
