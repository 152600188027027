import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

const AboutCricket = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Cricket Training Program at Soul Sportza</h1>
        <div className="section">
          <h2>Physical Conditioning</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Aerobic Conditioning: Running drills, interval training, and
              shuttle runs to improve cardiovascular endurance and stamina.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Strength Training: Exercises targeting upper body, lower body, and
              core muscles to enhance strength, power, and stability.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Agility and Speed Training: Cone drills, ladder drills, and
              reaction drills to improve agility, quickness, and explosiveness
              on the field.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Flexibility and Mobility: Stretching exercises, yoga, and dynamic
              warm-ups to improve flexibility, range of motion, and injury
              prevention.
            </p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/cricket03.jpg" alt="Sports" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h3>Technical Skills Development</h3>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Batting: Batting drills focusing on technique, footwork,
                  timing, and shot selection against various types of bowling.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Bowling: Bowling practice to improve accuracy, consistency,
                  pace, swing, spin, and variation.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Fielding: Fielding drills including catching, throwing,
                  diving, and agility exercises to improve fielding skills and
                  reflexes.
                </p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
                  Wicketkeeping: Wicketkeeping drills focusing on stance,
                  movement behind the stumps, catching, and quick glove work.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-1-container">
        
        <div className="home-1-box2">
        <div className="section">
          <h2>Tactical Training</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Game Awareness: Understanding match situations, reading the game,
              and making strategic decisions regarding batting, bowling, and
              fielding.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Role Specific Training: Developing specific skills and strategies
              for different roles in the team, such as opening batsman, fast
              bowler, spin bowler, or wicketkeeper.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Team Strategies: Practicing team tactics, field placements, and
              bowling plans for different opponents and conditions.
            </p>
          </div>
        </div>
        </div>

        <div className="home-1-box1">
          <img src="./images/cricket01.jpg" alt="Sports" />
        </div>
        
      </div>

        
        <div className="section">
          <h2>Mental Preparation</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Focus and Concentration: Incorporating mental exercises,
              visualization techniques, and focus drills to enhance
              concentration and decision-making under pressure.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Confidence Building: Providing positive reinforcement, setting
              achievable goals, and fostering a supportive team environment to
              boost players' confidence and self-belief.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Resilience and Adaptability: Encouraging players to learn from
              setbacks, stay composed under pressure, and adapt to changing game
              situations.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Match Play</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Practice Matches: Organizing intra-squad matches, net sessions,
              and practice games to simulate match conditions and allow players
              to apply learned skills and tactics in a competitive setting.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Competitive Drills: Competitive drills such as nets challenges,
              scenario-based games, and pressure situations to replicate match
              scenarios and develop mental toughness.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Cool Down: Including a structured cool-down routine with
              stretching and mobility exercises to aid in muscle recovery and
              reduce the risk of injury.
            </p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />
              Rest and Nutrition: Emphasizing the importance of adequate rest,
              hydration, and nutrition to support players' physical recovery and
              overall well-being.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutCricket;
