import React from 'react'
import Navbar from '../Navbar';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

const TennisClasses = () => {
  return (
    <>
    <Navbar />
    

    <div className="classes-container">
      <div className="box box1">
        <h1>Tennis Classes</h1>
        <img src="./images/tennis1.jpg" alt="Classes" />
        <div>
      <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Coaching Techniques:</strong> Basics like serving, volleying, and groundstrokes. Advanced training focuses on match play, strategy, and mental toughness.</p>
      <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Ground Practicing Sessions:</strong> Drills for stroke improvement, match simulations, and fitness exercises specific to tennis.</p>
    </div>
      </div>
      <div className="box box2">

      <div className="classes-box1">
        
        <Link style={{ textDecoration: "none" }} to="/abouttennis">
          <button><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />{" "}About{" "}</button>
        </Link>
        
        <Link style={{ textDecoration: "none" }} to="/contactpage">
          <button><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Contact</button>
        </Link>
        
      </div>


        <h2>Contact Us</h2>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" required></textarea>
          </div>
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>

    <Footer />
    
    </>
  )
}

export default TennisClasses;