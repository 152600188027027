import React from 'react'
import Navbar from '../Navbar'
import Footer from '../Footer'

const HealthHub = () => {
  return (
    <>
    <Navbar />
    <div className="ot-container">
    <h3>Health Hub at SoulSportza</h3>
  <p>Our Health Hub is designed to support the overall well-being of our athletes. We offer:</p>
  <div>
    <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Nutritional Guidance:</strong> Tailored diet plans to enhance performance.</p>
    <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Fitness Assessments:</strong> Regular evaluations to track progress and adjust training programs.</p>
    <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" /><strong>Kamineni Hospital Health Support:</strong> Partnership with Kamineni Hospital for regular health check-ups, injury prevention, and medical support.</p>
  </div>
  </div>

  <Footer />
  
  </>
  )
}

export default HealthHub