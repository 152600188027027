import React, { useState } from 'react';
import Footer from './Footer';
import Navbar from './Navbar';


const ContactPage = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', form);
    // Handle form submission logic here
  };

  return (
    <>

    <Navbar />
    
    <div className="contact-page">
      <h1>Get in Touch with Us</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Your name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={form.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Your email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            value={form.subject}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Your message (optional)</label>
          <textarea
            id="message"
            name="message"
            value={form.message}
            onChange={handleChange}
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
      <div className="contact-info">
        <h2 style={{color:'#000'}}>Contact Us</h2>
        <p>Tel: +91 73311 10064, +91 54 917 7593</p>
        <p>Email: <a href="mailto:info@soulsportza.com">info@soulsportza.com</a></p>
        <address>
          Soul Sportza Sports Academy Hyderabad<br />
          D.No: 3-12-92/555,<br />
          Rock Town Colony, L.B Nagar,<br />
          Hyderabad 500 068
        </address>
      </div>
    </div>
    
    <Footer />
    
    </>
  );
};

export default ContactPage;
