import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const AboutZumba = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Zumba Training Program at Soul Sportza</h1>
        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/ZUMBA03.jpg" alt="Zumba" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Physical Conditioning</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cardiovascular Fitness: Zumba routines incorporating dance and aerobic exercises to improve endurance and stamina.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Core Strengthening: Exercises targeting the core muscles to enhance stability and support for dance movements.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Flexibility: Stretching routines to improve flexibility and range of motion, crucial for dance performance.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Full-body Workout: Engages various muscle groups through dynamic movements and dance sequences.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="section">
          <h2>Dance Styles and Techniques</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Zumba Styles: Training in different Zumba styles such as salsa, merengue, cumbia, reggaeton, and more.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Rhythm and Musicality: Exercises to improve coordination, timing, and synchronization with music beats.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Choreography: Learning and practicing dance routines and combinations used in Zumba sessions.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Energy and Expression: Techniques to convey energy and emotion through dance movements.</p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/ZUMBA02.jpg" alt="Zumba" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Group Fitness and Motivation</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Community and Support: Participating in group Zumba sessions to foster motivation and camaraderie.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Positive Atmosphere: Creating a supportive environment for participants to enjoy and engage in fitness.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Mental Wellness: Stress-relieving benefits of Zumba, promoting mental clarity and well-being.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Mental Focus and Mindfulness</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Focus and Concentration: Techniques to improve focus during Zumba routines and choreography.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Mind-body Connection: Enhancing awareness of body movements and improving mindfulness through dance.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Stress Relief: Zumba as a form of stress relief and relaxation, promoting overall mental health.</p>
          </div>
        </div>
        <div className="section">
          <h2>Community Events and Performances</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Zumba Events: Participating in community Zumba events, workshops, and themed sessions.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Performance Opportunities: Showcasing skills and routines at Zumba parties, charity events, and local gatherings.</p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cool Down: Structured cool-down routines to aid muscle recovery and prevent stiffness.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Hydration and Nutrition: Emphasis on hydration and balanced nutrition to support energy levels and recovery.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutZumba;
