import React from 'react';
import Navbar from '../Navbar';
import Footer from '../Footer';

const AboutFitnessAthletic = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Fitness & Athletic Training Program at Soul Sportza</h1>
        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/exercise01.jpg" alt="Fitness" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Physical Conditioning</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Cardiovascular Fitness: Exercises to improve heart health and stamina, including running, cycling, and aerobic workouts.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Strength Training: Workouts targeting major muscle groups to build strength and endurance.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Flexibility and Mobility: Stretching routines and mobility exercises to enhance flexibility and prevent injuries.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Agility and Speed: Drills to improve agility, quickness, and coordination for better athletic performance.</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="section">
          <h2>Athletic Skills Development</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Sport-specific Training: Techniques and drills tailored to specific sports such as soccer, basketball, tennis, etc.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Power and Explosiveness: Exercises to develop explosive power and speed, essential for competitive sports.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Balance and Coordination: Drills to improve balance, body control, and spatial awareness.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Endurance Training: Conditioning programs to increase stamina and endurance for prolonged physical activities.</p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/KARATE02.jpg" alt="Fitness" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Mental Focus and Strategy</h2>
              <div>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Focus and Concentration: Techniques to improve mental focus and concentration during training and competitions.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Strategic Thinking: Analyzing game situations and developing strategies for effective decision-making.</p>
                <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Visualization: Mental exercises to visualize success and overcome challenges.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="section">
          <h2>Team Dynamics and Leadership</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Teamwork: Collaborative exercises to foster teamwork, communication, and synergy among team members.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Leadership Skills: Training on leadership qualities, including motivation, decision-making, and leading by example.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Conflict Resolution: Techniques to manage conflicts and maintain a positive team environment.</p>
          </div>
        </div>
        <div className="section">
          <h2>Competition and Performance</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Game Preparation: Preparing physically and mentally for competitions and matches.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Performance Analysis: Evaluating performance and identifying areas for improvement.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Goal Setting: Setting realistic goals and developing strategies to achieve them.</p>
          </div>
        </div>
        <div className="section">
          <h2>Recovery and Injury Prevention</h2>
          <div>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Recovery Techniques: Strategies to promote recovery, including rest, hydration, and nutrition.</p>
            <p><img src='./images/flagicon.png' alt="Flag Icon" className="flag-icon" />Injury Prevention: Exercises and techniques to minimize the risk of injuries during training and competition.</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutFitnessAthletic;
