import React from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

const AboutFootball = () => {
  return (
    <>
      <Navbar />
      <div className="training-program">
        <h1>Football Training Program at Soul Sportza</h1>
        <div className="section">
          <h2>Physical Conditioning</h2>
          <div>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Aerobic Conditioning: Running drills, interval training, and
              small-sided games to improve cardiovascular endurance.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Strength Training: Exercises targeting major muscle groups to
              enhance overall strength, power, and injury prevention.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Agility and Speed Training: Cone drills, ladder drills, and
              shuttle runs to improve agility, acceleration, and change of
              direction.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Flexibility and Mobility: Stretching exercises and dynamic
              warm-ups to improve range of motion and reduce the risk of injury.
            </p>
          </div>
        </div>

        <div className="home-1-container">
          <div className="home-1-box1">
            <img src="./images/Football02.jpg" alt="Sports" />
          </div>
          <div className="home-1-box2">
            <div className="section">
              <h2>Technical Skills Development</h2>
              <div>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Ball Control: Dribbling drills, ball mastery exercises, and
                  passing/receiving drills to improve touch, control, and
                  confidence on the ball.
                </p>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Shooting and Finishing: Shooting drills from various angles
                  and distances, as well as finishing exercises to improve
                  accuracy and technique.
                </p>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Passing and Crossing: Passing drills, possession games, and
                  crossing drills to enhance passing accuracy, vision, and
                  decision-making.
                </p>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Defensive Skills: Tackling drills, 1v1 defending, and
                  positional awareness exercises to improve defensive technique
                  and anticipation.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="home-1-container">
          
          <div className="home-1-box2">
            <div className="section">
              <h2>Tactical Training</h2>
              <div>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Team Shape and Formations: Practicing different formations,
                  positional roles, and tactical strategies (e.g., pressing,
                  counter-attacking) to suit the team's style of play.
                </p>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Set Pieces: Working on attacking and defending set pieces such
                  as corners, free kicks, and throw-ins to maximize scoring
                  opportunities and minimize conceding goals.
                </p>
                <p>
                  <img
                    src="./images/flagicon.png"
                    alt="Flag Icon"
                    className="flag-icon"
                  />
                  Game Understanding: Analyzing match footage, discussing game
                  scenarios, and implementing tactical adjustments during
                  training sessions to improve players' understanding of the
                  game.
                </p>
              </div>
            </div>
          </div>

          <div className="home-1-box1">
            <img src="./images/Football03.jpg" alt="Sports" />
          </div>
          
        </div>

        <div className="section">
          <h2>Mental Preparation</h2>
          <div>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Focus and Concentration: Incorporating mental exercises,
              visualization techniques, and mindfulness practices to enhance
              focus and concentration during matches.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Confidence Building: Providing positive reinforcement, setting
              achievable goals, and fostering a supportive team environment to
              boost players' confidence and self-belief.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Resilience and Adaptability: Encouraging players to learn from
              mistakes, overcome setbacks, and adapt to different game
              situations to develop resilience and mental toughness.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Match Preparation</h2>
          <div>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Small-Sided Games: Using small-sided games to simulate match
              conditions, reinforce technical and tactical concepts, and
              encourage creativity and decision-making under pressure.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Scrimmages and Full-Sided Games: Organizing practice matches to
              assess players' performance, team cohesion, and implementation of
              training concepts in a realistic game environment.
            </p>
          </div>
        </div>
        <div className="section">
          <h2>Regeneration and Recovery</h2>
          <div>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Cool Down: Including a structured cool-down routine with
              stretching and relaxation exercises to aid in muscle recovery and
              reduce the risk of injury.
            </p>
            <p>
              <img
                src="./images/flagicon.png"
                alt="Flag Icon"
                className="flag-icon"
              />
              Rest and Nutrition: Emphasizing the importance of adequate rest,
              hydration, and nutrition to support players' physical recovery and
              overall well-being.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutFootball;
